<template>
  <NuxtLink class="px-3 gap-1 h-12 cursor-pointer" :to="to">
    <!-- text-white opacity-[0.87] hover:text-white opacity-50 active:text-white/40 -->
    <div
      class="disabled:cursor-not-allowed flex flex-col items-center drop-shadow-lg"
    >
      <span
        :class="[
          isSelected
            ? 'font-bold text-button-big'
            : 'font-normal text-body-big',
        ]"
        class="text-white opacity-[0.87] lg:hover:text-white opacity-50 sm:md:active:!text-white opacity-[0.87] active:text-white/40 tracking-wide bg-clip-text"
      >
        <slot></slot>
      </span>
      <svg
        v-if="isKid && isSelected"
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="44"
        viewBox="0 0 87 44"
        fill="none"
        class="fill-brand-red"
      >
        <g filter="url(#filter0_dd_8307_45703)">
          <path
            d="M20 17.1582C20 16.5492 20.5365 16.081 21.1395 16.1663C24.531 16.6463 34.8615 18 43.5 18C52.1385 18 62.469 16.6463 65.8605 16.1663C66.4635 16.081 67 16.5492 67 17.1582C67 17.644 66.6487 18.0578 66.1686 18.1313C63.2617 18.5762 53.2029 20 43.5 20C33.7971 20 23.7383 18.5762 20.8314 18.1313C20.3513 18.0578 20 17.644 20 17.1582Z"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_8307_45703"
            x="0"
            y="0.15625"
            width="87"
            height="43.8438"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8307_45703"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_8307_45703"
              result="effect2_dropShadow_8307_45703"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_8307_45703"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <div
        v-else
        :class="[isSelected ? 'bg-brand-red' : 'bg-transparent']"
        class="lg:mt-2 mt-1 w-full h-0.5 rounded-full"
      ></div>
    </div>
  </NuxtLink>
</template>

<script setup>
const { isSelected } = defineProps({
  isSelected: { type: Boolean, default: () => false },
  disabled: { type: Boolean, default: () => false },
  readOnly: { type: Boolean, default: () => false },
  to: String,
});

const route = useRoute();
const isKid = computed(() => {
  return route.path.includes("kids");
});
</script>

<style lang="scss" scoped>
span {
  text-shadow: 0px 0px 20px #000;
}

.kidsIndicator {
  border: solid 5px theme("colors.white");
  border: solid 5px theme("colors.white");
  border-color: theme("colors.white") transparent transparent transparent;
  border-radius: 50%/100px 100px 0 0;
  transform: rotateX(180deg);
}
</style>
