<template>
  <div>
    <header
      ref="header"
      class="w-full flex flex-col mb-1 z-50 bg-brand-greyblack transition duration-200"
    >
      <div
        ref="minimizeTarget"
        class="px-4 md:px-6 py-[14px] md:py-4 flex justify-between items-center"
      >
        <Logo class="w-[104px] h-12"></Logo>
        <div class="flex items-center gap-x-4 md:gap-x-6 overflow-x-auto">
          <NuxtLink :to="localePath(isKidsMode ? '/kids/search' : '/search')">
            <ButtonsIcon
              icon-name="IconSearch"
              size="20"
              :class="{
                'bg-white/30': isCurrentRoute(
                  isKidsMode ? '/kids/search' : '/search'
                ),
              }"
            >
            </ButtonsIcon>
          </NuxtLink>

          <ButtonsCta
            v-if="!isKidsMode"
            class="variant-glass"
            @click="navStore.onKidsClick"
          >
            {{ $t("Kids") }}
          </ButtonsCta>
          <ButtonsCta
            v-else
            class="variant-secondary"
            @click="navStore.onExitKidsClick"
          >
            {{ $t("Exit Kids") }}
          </ButtonsCta>
          <ButtonsProfile v-if="sessionStore.isAuthenticated"></ButtonsProfile>
          <ModalButtonsGetStarted v-else></ModalButtonsGetStarted>
          <client-only>
            <LanguageSwitcher v-if="!sessionStore.token"></LanguageSwitcher>
          </client-only>
        </div>
      </div>
      <div
        v-show="!navStore.hideMenu"
        :class="[targetVisible ? '' : 'z-50 fixed top-0 w-full ']"
        ref="menuHeader"
        class="transition duration-75 bg-brand-greyblack"
      >
        <div class="relative">
          <!-- shadows -->
          <div
            class="z-10 absolute top-0 start-0 pointer-events-none touch-none"
          >
            <div
              class="absolute start-0 ltr:bg-gradient-to-l rtl:bg-gradient-to-r from-brand-greyblack/0 from-[28.51%] to-brand-greyblack/[87%] w-8 h-full"
            ></div>
            <div
              class="absolute end-0 ltr:bg-gradient-to-r rtl:bg-gradient-to-l from-brand-greyblack/0 from-[28.51%] to-brand-greyblack/[87%] w-8 h-full"
            ></div>
          </div>
          <div
            class="z-0 w-full h-full px-2 md:px-6 pt-1 flex items-center gap-x-4 overflow-x-auto overflow-y-hidden"
          >
            <template :key="key" v-for="(menu, key) in menuList">
              <template v-if="menu.children">
                <tippy
                  :arrow="false"
                  interactive
                  hideOnClick
                  class="flex"
                  placement="bottom-end"
                  trigger="click"
                  :offset="[164, 0]"
                  :onShown="useHideTippyOnChildrenClick"
                >
                  <template #default="{ state }">
                    <ButtonsMenu
                      :is-selected="isCurrentRoute(menu.path)"
                      :disabled="menu.disabled"
                      class="py-2 md:py-4 shrink-0"
                    >
                      <div class="flex items-center gap-x-1">
                        {{ $t(menu.name) }}
                        <Icon
                          v-if="state.isVisible"
                          name="IconChevronUp"
                        ></Icon>
                        <Icon v-else name="IconChevronDown"></Icon>
                      </div>
                    </ButtonsMenu>
                  </template>

                  <template #content>
                    <HeaderLiveTv></HeaderLiveTv>
                  </template>
                </tippy>
              </template>
              <template v-else>
                <ButtonsMenu
                  :is-selected="isCurrentRoute(menu.path)"
                  :disabled="menu.disabled"
                  :to="localePath(menu.path)"
                  class="py-2 md:py-4 shrink-0"
                >
                  {{ $t(menu.name) }}
                </ButtonsMenu>
              </template>
            </template>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup>
import { useNavStore } from "@/stores/nav";
import { useAuthStore } from "@/stores/auth";
import { useElementVisibility } from "@vueuse/core";
import { useSessionStore } from "@/stores/session";

const navStore = useNavStore();
const sessionStore = useSessionStore();

const headerNav = useHeaderNav();
const { isXs } = useBreakpoints();
const authStore = useAuthStore();
const isKidsMode = useIsKidsMode();

const isFirstStep = ref(true);
const isOpenBoardingModal = ref(false);
const minimizeTarget = ref(null);
const targetVisible = useElementVisibility(minimizeTarget);

const route = useRoute();
const menuList = computed(() => {
  return headerNav.menuItems.value.filter((menu) => {
    const userType = route.path.includes("kids") ? "kid" : "adult";
    return (
      ["both"].includes(menu.device) && [userType, "both"].includes(menu.type)
    );
  });
});

const isCurrentRoute = (path) => {
  const currentPath = route.path;
  const cleanedPath = currentPath.replace(/^\/\w{2}/, "");
  return path == cleanedPath;
};

//TV Scan Code Login Enable Login Popup
watch(
  () => authStore.openSignInPopup,
  (val) => {
    if (isXs && val) {
      isFirstStep.value = !val;
      isOpenBoardingModal.value = val;
      authStore.toggleSignInPopup(false);
    }
  },
  { immediate: true }
);
</script>
<style>
.mobile-modal {
  .ant-modal-content {
    @apply md:my-4 md:my-10;
  }
}
</style>
